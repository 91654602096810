import { IProjectsModel } from "../../stores/project/projectsModel";
import { IProjectModel } from "../../stores/project/projectModel";
import { observer } from "mobx-react-lite";
import { useMst } from "../../stores/Root";
import { toJS } from "mobx";

export type ProjectsListProps = {
  projects: IProjectModel[];
  setSelectedProjectById: (id?: string) => Promise<void>;
  isLoading: IProjectsModel["projectsIsLoading"];
  projectsSubMenuIsOpen: boolean;
  userHasMultipleAccounts: boolean;
};

export const ProjectsHOCWrapper = (Comp: any) =>
  observer(function Observed(props) {
    const state = useMst((store) => {
      return {
        projects: toJS(store.projectsStore.projects),
        isLoading: store.projectsStore.projectsIsLoading,
        setSelectedProjectById: store.projectsStore.setSelectedProjectById,
        projectsSubMenuIsOpen: store.projectsStore.projectsSubMenuIsOpen,
        useCases: store.useCasesStore.useCases,
        useCaseTags: store.useCasesStore.tags,
        userHasMultipleAccounts: store.userStore.hasMultipleAccounts,
      };
    });

    return <Comp {...state} {...props} />;
  });
