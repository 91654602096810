/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createProject =
  /* GraphQL */ `mutation CreateProject($input: CreateProjectInput!) {
  createProject(input: $input) {
    id
    projectAccountId
    title
    account {
      id
      gid
      name
      aliasId
      minisiteDomain
      createNewVideosWithBlingLogo
      mfaEnabled
      createdAt
      updatedAt
      __typename
    }
    videoParts {
      name
      jsonUrl
      mods
      updatedAt
      hasVideo
      origin
      playerVersionToUse
      __typename
    }
    allowCreateDataPoint
    allowDataConnect
    allowCrmConnect
    allowSdkConnect
    allowSendFormData
    analyticsReportUrl
    createdAt
    dataFiles {
      fileName
      tag
      createdAt
      __typename
    }
    description
    fileName
    jsonChanges
    jsonVidUrl
    playerSettings {
      autoplay
      autoplay_delay
      posterFrame
      showTimeline
      storiesMode
      color_loader
      color_ctrlBtns
      color_rail
      color_progress
      color_thumb
      color_bg
      muted
      autoReplay
      showBlingsLogo
      blingsLogoColor
      loadingText
      loadingImage
      __typename
    }
    projectVersions {
      nextToken
      __typename
    }
    formData {
      nextToken
      __typename
    }
    settings
    stateJsonSchemaStr
    settingsJsonSchemaStr
    thumbS3Url
    experiments {
      id
      type
      __typename
    }
    experimentOptimizationTechnique
    analyticsEnabled
    owner
    renders {
      nextToken
      __typename
    }
    notifications {
      nextToken
      __typename
    }
    aliasId
    minisiteConfigs {
      scenes
      title
      description
      thumbUrl
      faviconUrl
      preCreate
      __typename
    }
    playerVersionToUse
    customHtml
    dynamicThumbnails {
      name
      id
      createdAt
      updatedAt
      __typename
    }
    publishedAt
    allowedOrigins
    flowDiagram {
      __typename
    }
    textToVideoScript
    instantiatedFromUseCase
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateProjectMutationVariables,
    APITypes.CreateProjectMutation
  >;
export const updateProject =
  /* GraphQL */ `mutation UpdateProject($input: UpdateProjectInput!) {
  updateProject(input: $input) {
    id
    projectAccountId
    title
    account {
      id
      gid
      name
      aliasId
      minisiteDomain
      createNewVideosWithBlingLogo
      mfaEnabled
      createdAt
      updatedAt
      __typename
    }
    videoParts {
      name
      jsonUrl
      mods
      updatedAt
      hasVideo
      origin
      playerVersionToUse
      __typename
    }
    allowCreateDataPoint
    allowDataConnect
    allowCrmConnect
    allowSdkConnect
    allowSendFormData
    analyticsReportUrl
    createdAt
    dataFiles {
      fileName
      tag
      createdAt
      __typename
    }
    description
    fileName
    jsonChanges
    jsonVidUrl
    playerSettings {
      autoplay
      autoplay_delay
      posterFrame
      showTimeline
      storiesMode
      color_loader
      color_ctrlBtns
      color_rail
      color_progress
      color_thumb
      color_bg
      muted
      autoReplay
      showBlingsLogo
      blingsLogoColor
      loadingText
      loadingImage
      __typename
    }
    projectVersions {
      nextToken
      __typename
    }
    formData {
      nextToken
      __typename
    }
    settings
    stateJsonSchemaStr
    settingsJsonSchemaStr
    thumbS3Url
    experiments {
      id
      type
      __typename
    }
    experimentOptimizationTechnique
    analyticsEnabled
    owner
    renders {
      nextToken
      __typename
    }
    notifications {
      nextToken
      __typename
    }
    aliasId
    minisiteConfigs {
      scenes
      title
      description
      thumbUrl
      faviconUrl
      preCreate
      __typename
    }
    playerVersionToUse
    customHtml
    dynamicThumbnails {
      name
      id
      createdAt
      updatedAt
      __typename
    }
    publishedAt
    allowedOrigins
    flowDiagram {
      __typename
    }
    textToVideoScript
    instantiatedFromUseCase
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateProjectMutationVariables,
    APITypes.UpdateProjectMutation
  >;
export const updateProjectVersion =
  /* GraphQL */ `mutation UpdateProjectVersion($input: UpdateProjectVersionInput!) {
  updateProjectVersion(input: $input) {
    id
    accountOwner
    owner
    stateJsonSchemaStr
    settingsJsonSchemaStr
    settings
    videoParts {
      name
      jsonUrl
      mods
      updatedAt
      hasVideo
      origin
      playerVersionToUse
      __typename
    }
    updatedAt
    playerVersionToUse
    experiments {
      id
      type
      __typename
    }
    experimentOptimizationTechnique
    textToVideoScript
    flowDiagram {
      __typename
    }
    createdAt
    projectProjectVersionsId
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateProjectVersionMutationVariables,
    APITypes.UpdateProjectVersionMutation
  >;
export const createUseCase = /* GraphQL */ `mutation CreateUseCase(
  $input: CreateUseCaseInput!
  $condition: ModelUseCaseConditionInput
) {
  createUseCase(input: $input, condition: $condition) {
    id
    name
    projectId
    images
    shortDescription
    longDescription
    thumbnail
    demoLink
    videoPreview
    lineupId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUseCaseMutationVariables,
  APITypes.CreateUseCaseMutation
>;
export const updateUseCase = /* GraphQL */ `mutation UpdateUseCase(
  $input: UpdateUseCaseInput!
  $condition: ModelUseCaseConditionInput
) {
  updateUseCase(input: $input, condition: $condition) {
    id
    name
    projectId
    images
    shortDescription
    longDescription
    thumbnail
    demoLink
    videoPreview
    lineupId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUseCaseMutationVariables,
  APITypes.UpdateUseCaseMutation
>;
export const deleteUseCase = /* GraphQL */ `mutation DeleteUseCase(
  $input: DeleteUseCaseInput!
  $condition: ModelUseCaseConditionInput
) {
  deleteUseCase(input: $input, condition: $condition) {
    id
    name
    projectId
    images
    shortDescription
    longDescription
    thumbnail
    demoLink
    videoPreview
    lineupId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUseCaseMutationVariables,
  APITypes.DeleteUseCaseMutation
>;
export const createUseCaseTag = /* GraphQL */ `mutation CreateUseCaseTag(
  $input: CreateUseCaseTagInput!
  $condition: ModelUseCaseTagConditionInput
) {
  createUseCaseTag(input: $input, condition: $condition) {
    tag
    useCaseIds
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUseCaseTagMutationVariables,
  APITypes.CreateUseCaseTagMutation
>;
export const updateUseCaseTag = /* GraphQL */ `mutation UpdateUseCaseTag(
  $input: UpdateUseCaseTagInput!
  $condition: ModelUseCaseTagConditionInput
) {
  updateUseCaseTag(input: $input, condition: $condition) {
    tag
    useCaseIds
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUseCaseTagMutationVariables,
  APITypes.UpdateUseCaseTagMutation
>;
export const deleteUseCaseTag = /* GraphQL */ `mutation DeleteUseCaseTag(
  $input: DeleteUseCaseTagInput!
  $condition: ModelUseCaseTagConditionInput
) {
  deleteUseCaseTag(input: $input, condition: $condition) {
    tag
    useCaseIds
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUseCaseTagMutationVariables,
  APITypes.DeleteUseCaseTagMutation
>;
export const createAccount = /* GraphQL */ `mutation CreateAccount(
  $input: CreateAccountInput!
  $condition: ModelAccountConditionInput
) {
  createAccount(input: $input, condition: $condition) {
    id
    gid
    name
    projects {
      nextToken
      __typename
    }
    fileuploads {
      originalName
      createdAt
      fileName
      fileStatus
      fileError
      numRecords
      numErrors
      totalRecords
      writtenRecords
      __typename
    }
    integrations {
      hubspot
      __typename
    }
    aliasId
    fonts {
      fontFamily
      __typename
    }
    accountType {
      levelName
      __typename
    }
    minisiteDomain
    createNewVideosWithBlingLogo
    mfaEnabled
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAccountMutationVariables,
  APITypes.CreateAccountMutation
>;
export const updateAccount = /* GraphQL */ `mutation UpdateAccount(
  $input: UpdateAccountInput!
  $condition: ModelAccountConditionInput
) {
  updateAccount(input: $input, condition: $condition) {
    id
    gid
    name
    projects {
      nextToken
      __typename
    }
    fileuploads {
      originalName
      createdAt
      fileName
      fileStatus
      fileError
      numRecords
      numErrors
      totalRecords
      writtenRecords
      __typename
    }
    integrations {
      hubspot
      __typename
    }
    aliasId
    fonts {
      fontFamily
      __typename
    }
    accountType {
      levelName
      __typename
    }
    minisiteDomain
    createNewVideosWithBlingLogo
    mfaEnabled
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAccountMutationVariables,
  APITypes.UpdateAccountMutation
>;
export const deleteAccount = /* GraphQL */ `mutation DeleteAccount(
  $input: DeleteAccountInput!
  $condition: ModelAccountConditionInput
) {
  deleteAccount(input: $input, condition: $condition) {
    id
    gid
    name
    projects {
      nextToken
      __typename
    }
    fileuploads {
      originalName
      createdAt
      fileName
      fileStatus
      fileError
      numRecords
      numErrors
      totalRecords
      writtenRecords
      __typename
    }
    integrations {
      hubspot
      __typename
    }
    aliasId
    fonts {
      fontFamily
      __typename
    }
    accountType {
      levelName
      __typename
    }
    minisiteDomain
    createNewVideosWithBlingLogo
    mfaEnabled
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAccountMutationVariables,
  APITypes.DeleteAccountMutation
>;
export const createFormData = /* GraphQL */ `mutation CreateFormData(
  $input: CreateFormDataInput!
  $condition: ModelFormDataConditionInput
) {
  createFormData(input: $input, condition: $condition) {
    id
    accountOwner
    projectFormDataId
    project {
      id
      projectAccountId
      title
      allowCreateDataPoint
      allowDataConnect
      allowCrmConnect
      allowSdkConnect
      allowSendFormData
      analyticsReportUrl
      createdAt
      description
      fileName
      jsonChanges
      jsonVidUrl
      settings
      stateJsonSchemaStr
      settingsJsonSchemaStr
      thumbS3Url
      experimentOptimizationTechnique
      analyticsEnabled
      owner
      aliasId
      playerVersionToUse
      customHtml
      publishedAt
      allowedOrigins
      textToVideoScript
      instantiatedFromUseCase
      updatedAt
      __typename
    }
    data
    sessionId
    userId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFormDataMutationVariables,
  APITypes.CreateFormDataMutation
>;
export const updateFormData = /* GraphQL */ `mutation UpdateFormData(
  $input: UpdateFormDataInput!
  $condition: ModelFormDataConditionInput
) {
  updateFormData(input: $input, condition: $condition) {
    id
    accountOwner
    projectFormDataId
    project {
      id
      projectAccountId
      title
      allowCreateDataPoint
      allowDataConnect
      allowCrmConnect
      allowSdkConnect
      allowSendFormData
      analyticsReportUrl
      createdAt
      description
      fileName
      jsonChanges
      jsonVidUrl
      settings
      stateJsonSchemaStr
      settingsJsonSchemaStr
      thumbS3Url
      experimentOptimizationTechnique
      analyticsEnabled
      owner
      aliasId
      playerVersionToUse
      customHtml
      publishedAt
      allowedOrigins
      textToVideoScript
      instantiatedFromUseCase
      updatedAt
      __typename
    }
    data
    sessionId
    userId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFormDataMutationVariables,
  APITypes.UpdateFormDataMutation
>;
export const deleteFormData = /* GraphQL */ `mutation DeleteFormData(
  $input: DeleteFormDataInput!
  $condition: ModelFormDataConditionInput
) {
  deleteFormData(input: $input, condition: $condition) {
    id
    accountOwner
    projectFormDataId
    project {
      id
      projectAccountId
      title
      allowCreateDataPoint
      allowDataConnect
      allowCrmConnect
      allowSdkConnect
      allowSendFormData
      analyticsReportUrl
      createdAt
      description
      fileName
      jsonChanges
      jsonVidUrl
      settings
      stateJsonSchemaStr
      settingsJsonSchemaStr
      thumbS3Url
      experimentOptimizationTechnique
      analyticsEnabled
      owner
      aliasId
      playerVersionToUse
      customHtml
      publishedAt
      allowedOrigins
      textToVideoScript
      instantiatedFromUseCase
      updatedAt
      __typename
    }
    data
    sessionId
    userId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFormDataMutationVariables,
  APITypes.DeleteFormDataMutation
>;
export const createNotification = /* GraphQL */ `mutation CreateNotification(
  $input: CreateNotificationInput!
  $condition: ModelNotificationConditionInput
) {
  createNotification(input: $input, condition: $condition) {
    id
    type
    subType
    status
    title
    message
    read
    owner
    projectId
    project {
      id
      projectAccountId
      title
      allowCreateDataPoint
      allowDataConnect
      allowCrmConnect
      allowSdkConnect
      allowSendFormData
      analyticsReportUrl
      createdAt
      description
      fileName
      jsonChanges
      jsonVidUrl
      settings
      stateJsonSchemaStr
      settingsJsonSchemaStr
      thumbS3Url
      experimentOptimizationTechnique
      analyticsEnabled
      owner
      aliasId
      playerVersionToUse
      customHtml
      publishedAt
      allowedOrigins
      textToVideoScript
      instantiatedFromUseCase
      updatedAt
      __typename
    }
    createdAt
    extra
    updatedAt
    projectNotificationsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateNotificationMutationVariables,
  APITypes.CreateNotificationMutation
>;
export const updateNotification = /* GraphQL */ `mutation UpdateNotification(
  $input: UpdateNotificationInput!
  $condition: ModelNotificationConditionInput
) {
  updateNotification(input: $input, condition: $condition) {
    id
    type
    subType
    status
    title
    message
    read
    owner
    projectId
    project {
      id
      projectAccountId
      title
      allowCreateDataPoint
      allowDataConnect
      allowCrmConnect
      allowSdkConnect
      allowSendFormData
      analyticsReportUrl
      createdAt
      description
      fileName
      jsonChanges
      jsonVidUrl
      settings
      stateJsonSchemaStr
      settingsJsonSchemaStr
      thumbS3Url
      experimentOptimizationTechnique
      analyticsEnabled
      owner
      aliasId
      playerVersionToUse
      customHtml
      publishedAt
      allowedOrigins
      textToVideoScript
      instantiatedFromUseCase
      updatedAt
      __typename
    }
    createdAt
    extra
    updatedAt
    projectNotificationsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateNotificationMutationVariables,
  APITypes.UpdateNotificationMutation
>;
export const deleteNotification = /* GraphQL */ `mutation DeleteNotification(
  $input: DeleteNotificationInput!
  $condition: ModelNotificationConditionInput
) {
  deleteNotification(input: $input, condition: $condition) {
    id
    type
    subType
    status
    title
    message
    read
    owner
    projectId
    project {
      id
      projectAccountId
      title
      allowCreateDataPoint
      allowDataConnect
      allowCrmConnect
      allowSdkConnect
      allowSendFormData
      analyticsReportUrl
      createdAt
      description
      fileName
      jsonChanges
      jsonVidUrl
      settings
      stateJsonSchemaStr
      settingsJsonSchemaStr
      thumbS3Url
      experimentOptimizationTechnique
      analyticsEnabled
      owner
      aliasId
      playerVersionToUse
      customHtml
      publishedAt
      allowedOrigins
      textToVideoScript
      instantiatedFromUseCase
      updatedAt
      __typename
    }
    createdAt
    extra
    updatedAt
    projectNotificationsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteNotificationMutationVariables,
  APITypes.DeleteNotificationMutation
>;
export const deleteProject = /* GraphQL */ `mutation DeleteProject(
  $input: DeleteProjectInput!
  $condition: ModelProjectConditionInput
) {
  deleteProject(input: $input, condition: $condition) {
    id
    projectAccountId
    title
    account {
      id
      gid
      name
      aliasId
      minisiteDomain
      createNewVideosWithBlingLogo
      mfaEnabled
      createdAt
      updatedAt
      __typename
    }
    videoParts {
      name
      jsonUrl
      mods
      updatedAt
      hasVideo
      origin
      playerVersionToUse
      __typename
    }
    allowCreateDataPoint
    allowDataConnect
    allowCrmConnect
    allowSdkConnect
    allowSendFormData
    analyticsReportUrl
    createdAt
    dataFiles {
      fileName
      tag
      createdAt
      __typename
    }
    description
    fileName
    jsonChanges
    jsonVidUrl
    playerSettings {
      autoplay
      autoplay_delay
      posterFrame
      showTimeline
      storiesMode
      color_loader
      color_ctrlBtns
      color_rail
      color_progress
      color_thumb
      color_bg
      muted
      autoReplay
      showBlingsLogo
      blingsLogoColor
      loadingText
      loadingImage
      __typename
    }
    projectVersions {
      nextToken
      __typename
    }
    formData {
      nextToken
      __typename
    }
    settings
    stateJsonSchemaStr
    settingsJsonSchemaStr
    thumbS3Url
    experiments {
      id
      type
      __typename
    }
    experimentOptimizationTechnique
    analyticsEnabled
    owner
    renders {
      nextToken
      __typename
    }
    notifications {
      nextToken
      __typename
    }
    aliasId
    minisiteConfigs {
      scenes
      title
      description
      thumbUrl
      faviconUrl
      preCreate
      __typename
    }
    playerVersionToUse
    customHtml
    dynamicThumbnails {
      name
      id
      createdAt
      updatedAt
      __typename
    }
    publishedAt
    allowedOrigins
    flowDiagram {
      __typename
    }
    textToVideoScript
    instantiatedFromUseCase
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProjectMutationVariables,
  APITypes.DeleteProjectMutation
>;
export const createProjectVersion =
  /* GraphQL */ `mutation CreateProjectVersion(
  $input: CreateProjectVersionInput!
  $condition: ModelProjectVersionConditionInput
) {
  createProjectVersion(input: $input, condition: $condition) {
    id
    accountOwner
    owner
    stateJsonSchemaStr
    settingsJsonSchemaStr
    settings
    videoParts {
      name
      jsonUrl
      mods
      updatedAt
      hasVideo
      origin
      playerVersionToUse
      __typename
    }
    updatedAt
    playerVersionToUse
    experiments {
      id
      type
      __typename
    }
    experimentOptimizationTechnique
    textToVideoScript
    flowDiagram {
      __typename
    }
    createdAt
    projectProjectVersionsId
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateProjectVersionMutationVariables,
    APITypes.CreateProjectVersionMutation
  >;
export const deleteProjectVersion =
  /* GraphQL */ `mutation DeleteProjectVersion(
  $input: DeleteProjectVersionInput!
  $condition: ModelProjectVersionConditionInput
) {
  deleteProjectVersion(input: $input, condition: $condition) {
    id
    accountOwner
    owner
    stateJsonSchemaStr
    settingsJsonSchemaStr
    settings
    videoParts {
      name
      jsonUrl
      mods
      updatedAt
      hasVideo
      origin
      playerVersionToUse
      __typename
    }
    updatedAt
    playerVersionToUse
    experiments {
      id
      type
      __typename
    }
    experimentOptimizationTechnique
    textToVideoScript
    flowDiagram {
      __typename
    }
    createdAt
    projectProjectVersionsId
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteProjectVersionMutationVariables,
    APITypes.DeleteProjectVersionMutation
  >;
export const createServerRender = /* GraphQL */ `mutation CreateServerRender(
  $input: CreateServerRenderInput!
  $condition: ModelServerRenderConditionInput
) {
  createServerRender(input: $input, condition: $condition) {
    id
    projectId
    project {
      id
      projectAccountId
      title
      allowCreateDataPoint
      allowDataConnect
      allowCrmConnect
      allowSdkConnect
      allowSendFormData
      analyticsReportUrl
      createdAt
      description
      fileName
      jsonChanges
      jsonVidUrl
      settings
      stateJsonSchemaStr
      settingsJsonSchemaStr
      thumbS3Url
      experimentOptimizationTechnique
      analyticsEnabled
      owner
      aliasId
      playerVersionToUse
      customHtml
      publishedAt
      allowedOrigins
      textToVideoScript
      instantiatedFromUseCase
      updatedAt
      __typename
    }
    scenes
    data
    env
    outputFileName
    quality
    frameToStart
    frameToEnd
    format
    status {
      ready
      status
      downloadLink
      code
      __typename
    }
    cognitoUserIdentifier
    cognitoGroupOwner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateServerRenderMutationVariables,
  APITypes.CreateServerRenderMutation
>;
export const updateServerRender = /* GraphQL */ `mutation UpdateServerRender(
  $input: UpdateServerRenderInput!
  $condition: ModelServerRenderConditionInput
) {
  updateServerRender(input: $input, condition: $condition) {
    id
    projectId
    project {
      id
      projectAccountId
      title
      allowCreateDataPoint
      allowDataConnect
      allowCrmConnect
      allowSdkConnect
      allowSendFormData
      analyticsReportUrl
      createdAt
      description
      fileName
      jsonChanges
      jsonVidUrl
      settings
      stateJsonSchemaStr
      settingsJsonSchemaStr
      thumbS3Url
      experimentOptimizationTechnique
      analyticsEnabled
      owner
      aliasId
      playerVersionToUse
      customHtml
      publishedAt
      allowedOrigins
      textToVideoScript
      instantiatedFromUseCase
      updatedAt
      __typename
    }
    scenes
    data
    env
    outputFileName
    quality
    frameToStart
    frameToEnd
    format
    status {
      ready
      status
      downloadLink
      code
      __typename
    }
    cognitoUserIdentifier
    cognitoGroupOwner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateServerRenderMutationVariables,
  APITypes.UpdateServerRenderMutation
>;
export const deleteServerRender = /* GraphQL */ `mutation DeleteServerRender(
  $input: DeleteServerRenderInput!
  $condition: ModelServerRenderConditionInput
) {
  deleteServerRender(input: $input, condition: $condition) {
    id
    projectId
    project {
      id
      projectAccountId
      title
      allowCreateDataPoint
      allowDataConnect
      allowCrmConnect
      allowSdkConnect
      allowSendFormData
      analyticsReportUrl
      createdAt
      description
      fileName
      jsonChanges
      jsonVidUrl
      settings
      stateJsonSchemaStr
      settingsJsonSchemaStr
      thumbS3Url
      experimentOptimizationTechnique
      analyticsEnabled
      owner
      aliasId
      playerVersionToUse
      customHtml
      publishedAt
      allowedOrigins
      textToVideoScript
      instantiatedFromUseCase
      updatedAt
      __typename
    }
    scenes
    data
    env
    outputFileName
    quality
    frameToStart
    frameToEnd
    format
    status {
      ready
      status
      downloadLink
      code
      __typename
    }
    cognitoUserIdentifier
    cognitoGroupOwner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteServerRenderMutationVariables,
  APITypes.DeleteServerRenderMutation
>;
export const sendInstantNotification =
  /* GraphQL */ `mutation SendInstantNotification($input: SendInstantNotificationInput) {
  sendInstantNotification(input: $input) {
    type
    subType
    status
    title
    message
    owner
    projectId
    extra
    __typename
  }
}
` as GeneratedMutation<
    APITypes.SendInstantNotificationMutationVariables,
    APITypes.SendInstantNotificationMutation
  >;
