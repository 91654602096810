import reactCSS from "reactcss";
import { Popover } from "antd";
import { ChromePicker, ColorChangeHandler } from "react-color";

const defaultStyles: any = reactCSS({
  default: {
    color: {
      width: "0.8rem",
      height: "0.8rem",
      borderRadius: "0.1rem",
    },
    swatch: {
      padding: "0.4rem",
      background: "#fff",
      borderRadius: "0.1rem",
      boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
      display: "inline-block",
      cursor: "pointer",
      position: "relative",
      bottom: "0rem",
    },
  },
});

export function ColorPicker({
  color,
  disableAlpha,
  onChange,
  size,
  disabled,
}: {
  color: string;
  disableAlpha?: boolean;
  onChange: ColorChangeHandler | undefined;
  size?: number;
  disabled?: boolean;
}) {
  const styles =
    size == null
      ? defaultStyles
      : reactCSS({
          default: {
            color: {
              width: 0.8 * size + "rem",
              height: 0.8 * size + "rem",
              borderRadius: 0.1 * size + "rem",
            },
            swatch: {
              padding: 0.1 * size + "rem",
              background: "#fff",
              borderRadius: 0.1 * size + "rem",
              boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
              display: "inline-block",
              cursor: "pointer",
              position: "relative",
              bottom: "0rem",
            },
          },
        });
  return (
    <div style={{ display: "flex", alignItems: "flex-start", gap: "1rem" }}>
      <Popover
        placement="bottom"
        content={
          <ChromePicker
            disableAlpha={disableAlpha !== undefined ? disableAlpha : true}
            color={color}
            onChange={onChange}
          />
        }
        trigger="click"
        //trigger={disabled ? "none" : "click"}
      >
        <div
          style={{
            ...styles.swatch,
            ...(disabled ? { cursor: "not-allowed" } : {}),
          }}
        >
          <div style={{ ...styles.color, background: color }}></div>
        </div>
      </Popover>
    </div>
  );
}
