import { IProjectModel } from "../../../stores/project/projectModel";
import { dateFormatter } from "../../../helpers/timeAgo.helpers";
import "./ProjectGalleryCard.scss";
import { Image } from "antd";
const fallback =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAD0SURBVHgB3ZQ9DgFBFIDfGzQqLRHZ1k8kG/TcgBtwApxAnAAnwAm4ARcQGrRbSChVCsm+5+3GLsXushuVr5j/fPNm8mYQhO3h0iWgHjBrEIwBwMNKMTvzW4Cb/akt1RTCgNivFDJjr6k4gBrIrhAK5q6UtnB7PGtMtBBDSiXvugg/HtML7Smrk0kLQEhZfbolmgoiYt870cqROUQWEpuedxhZ6McfC9k0W5Jt158Jq+XcUsWULkluuDJUVxG+Br4GrScIoOfThkJsMPMcEdci38WJaSKDozA+eSlDp2lJpWq7UdZKWckn7ryH7h8ZGsTcD/ocHsyrU6deRoztAAAAAElFTkSuQmCC";

export default function ProjectGalleryCard({
  project,
  handleClick,
  userHasMultipleAccounts,
}: {
  project: IProjectModel;
  handleClick: (e: any) => void;
  userHasMultipleAccounts: boolean;
}) {
  const date = `${
    project?.workSpaceUpdatedAt != null ||
    project.publishedAt != null ||
    project?.updatedAt != null
      ? dateFormatter(
          new Date(
            project?.workSpaceUpdatedAt ||
              project.publishedAt ||
              (project?.updatedAt as any)
          )
        )
      : ""
  }`;

  return (
    <div
      className="project-gallery-card"
      onClick={() => handleClick(project.id)}
    >
      {userHasMultipleAccounts && (
        <div
          className="project-account-name text"
          title={project.projectAccountId}
        >
          {project.projectAccountId}
        </div>
      )}
      <div className="video-thumb-container">
        <Image
          className="video-thumb"
          src={project.thumbS3Url ? project.thumbS3Url : "error"}
          alt="project thumbnail"
          fallback={fallback}
          preview={false}
          placeholder={true}
        />
      </div>
      <div className="project-details">
        <div className="project-gallery-card-title" title={project.title}>
          {project.title}
        </div>
        <div className="project-gallery-card-date text">{date}</div>
      </div>
    </div>
  );
}
