import "./StaticTemplate.scss";
import { Button } from "antd";
import { PATHS } from "../../../PATHS";

export interface StaticTemplateProps {
  props: {
    title: string;
    content: Array<{ subtitle: string; text: string }>;
    footerTitle: string;
    footerText?: string;
    buttonText: string;
    redirectPage?: string;
    onButtonClick?: () => void;
  };
}

export default function StaticTemplate({ props }: StaticTemplateProps) {
  const handleButtonClick = () => {
    if (props.redirectPage) {
      const isInternalPath = (Object.values(PATHS) as string[]).includes(
        props.redirectPage
      );

      if (isInternalPath) {
        window.location.href =
          window.location.protocol +
          "//" +
          window.location.host +
          "/" +
          props.redirectPage;
      } else {
        window.open(props.redirectPage, "_blank");
      }
    } else if (props.onButtonClick) {
      props.onButtonClick();
    }
  };

  return (
    <div className="use-case-wrapper">
      <div className="left-side">
        <div className="video-content"></div>
      </div>
      <div className="right-side">
        <div className="static-container">
          <div className="static-title">
            <h1>{props.title}</h1>
            <div className="static-separator"></div>
            {props.content.map((item) => (
              <div className="static-text-content" key={item.subtitle}>
                <h2>{item.subtitle}</h2>
                <p>{item.text}</p>
              </div>
            ))}
          </div>
          <div className="static-about-template">
            <h3>{props.footerTitle}</h3>
            {props.footerText && <p>{props.footerText}</p>}
          </div>
          <div className="static-separator"></div>
        </div>
        <div className="action">
          <Button className="use-template-btn" onClick={handleButtonClick}>
            {props.buttonText}
          </Button>
        </div>
      </div>
    </div>
  );
}
