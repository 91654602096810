import { Layout, Typography } from "antd";
import BlingsModal from "../BlingsModal/BlingsModal";
import { BlingsDynamicForm } from "../BlingsCard_DynamicData/BlingsDynamicForm";
import { BlingsCard } from "../BlingsCard/BlingsCard";
import { Content } from "antd/es/layout/layout";
import { useState, useEffect, useContext } from "react";
import useDebounce from "../../helpers/DebounceHook";
import BlingsDataPointSelector from "./BlingsDataPointSelector";
import { BlingsNewDataInput } from "./BlingsNewDataInput";
import { OverviewPageContext } from "../../view/ProjectDemoPage/OverviewPage";
import { jsonSchemaGetExamples } from "../../helpers/jsonSchema.helpers";
import _ from "lodash";
import { useBlingsDynamicForm } from "../../helpers/BlingsDynamicForm";
import "./BlingsDynamicDataModal.scss";
import SceneOrLineupSelect from "../SceneOrLineupSelect";
import {
  IFlowScene,
  ISdkSettings,
} from "@blings/blings-player/lib/src/SDK/sdk.api";
import { PlayerComponent } from "../../view/ProjectDemoPage/PlayerComponent";

const { Title } = Typography;

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<
    React.SetStateAction<{ modalOpen: boolean; mode: "create" | "edit" }>
  >;
  initialSelectedDataPointId?: string;
  initialSavedFormData?: any;
  dataPointSelectorProps: {
    selectedDataPointId: string | undefined;
    setSelectedDataPointId: React.Dispatch<
      React.SetStateAction<string | undefined>
    >;
    fillDataFromRecord: (dataId: string | undefined) => void;
  };
  mode?: "create" | "edit";
}

export default function BlingsDynamicDataModal({
  isModalOpen,
  setIsModalOpen,
  initialSelectedDataPointId,
  initialSavedFormData,
  dataPointSelectorProps,
  mode,
}: Props) {
  // Context definitions
  const { dataPointsFromManual, dataPointsFromCSV, project } =
    useContext(OverviewPageContext);
  const dataExamples = jsonSchemaGetExamples(
    JSON.parse(project.stateJsonSchemaStr || "{}")
  );
  const schema = JSON.parse(project.stateJsonSchemaStr || "{}");

  // State definitions
  const {
    selectedDataPointId,
    setSelectedDataPointId,
    lastFormChange,
    currentFormData,
    setLastFormChange,
    setCurrentFormData,
    savedFormData,
    setSavedFormData,
    setFormHasChanged,
    recordChanged,
    setChangedFromRecordData,
    debouncedValue,
    submit,
    fillDataFromRecord,
    setCurrentDataPointsFromManual,
    setCurrentDataPointsFromCSV,
  } = useBlingsDynamicForm(
    project,
    dataExamples,
    schema,
    dataPointsFromManual,
    dataPointsFromCSV,
    initialSelectedDataPointId,
    initialSavedFormData
  );

  const [creatingNewDataPoint, setCreatingNewDatapoint] = useState(
    mode === "create"
  );

  // Use effect
  useEffect(() => {
    submit.current();
  }, [debouncedValue]);

  useEffect(() => {
    setCurrentDataPointsFromCSV(dataPointsFromCSV);
  }, [dataPointsFromCSV]);

  useEffect(() => {
    setCurrentDataPointsFromManual(dataPointsFromManual);
  }, [dataPointsFromManual]);

  useEffect(() => {
    if (selectedDataPointId && dataPointsFromManual) {
      setSavedFormData(dataPointsFromManual[selectedDataPointId]);
    }
  }, [dataPointsFromManual, dataPointsFromCSV]);

  const showSDKProps = {
    project,
    showExtraOptions: false,
    data: currentFormData,
    settings: project.settings,
    selectedDataID: selectedDataPointId,
    recordChanged,
    frameIndicator: true,
    renderMp4: true,
  };

  // Extra logic
  const hasDataPoints =
    (dataPointsFromManual && Object.keys(dataPointsFromManual).length > 0) ||
    (dataPointsFromCSV && Object.keys(dataPointsFromCSV).length > 0) ||
    false;

  const dynamicFormProps = {
    schema,
    currentFormData,
    setLastFormChange,
    setCurrentFormData,
    setFormHasChanged,
    setChangedFromRecordData,
    submit,
  };

  const dataInputProps = {
    recordChanged,
    selectedDataPointId,
    setSelectedDataPointId,
    setCurrentFormData: (change: any) => {
      setLastFormChange(change);
      setSavedFormData(change);
      setCurrentFormData(change);
    },
    currentFormData: lastFormChange,
    savedFormData,
    schema,
  };

  const { videoPartNames } = showSDKProps.project;
  const [scenes, setScenes] = useState<IFlowScene[]>(
    (videoPartNames && [videoPartNames[0]]) || []
  );
  const [isLoading, setIsLoading] = useState(true);

  const selectedScenes = showSDKProps.project.workspaceVideoParts?.filter(
    (vp: any) => vp.name && scenes.includes(vp.name)
  );
  const playerVersionToUse =
    showSDKProps.project.playerVersionToUse || undefined;
  const debouncedSettings = useDebounce<ISdkSettings>(
    showSDKProps.settings,
    1000
  );
  const debouncedScenes = useDebounce<IFlowScene[]>(scenes, 1000);
  const vertical = selectedScenes?.length
    ? selectedScenes[0]?.jsonData?.h > selectedScenes[0]?.jsonData?.w
    : false;
  const hasFormDataChanged = !_.isEqual(lastFormChange, savedFormData);

  return (
    <BlingsModal
      isModalOpen={isModalOpen}
      title={
        <>
          <div className="header-container">
            <Title className="blings-modal-title" level={3}>
              Dynamic Data Preview
            </Title>
            <div
              className="scene-selector-container"
              style={{ padding: 0, marginRight: "20px" }}
            >
              {videoPartNames?.length >= 0 && (
                <div
                  style={{
                    display: "flex",
                    gap: "16px",
                    alignItems: "center",
                  }}
                >
                  <p className="watching-title">Watching</p>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      padding: "0",
                      marginRight: "20px",
                    }}
                  >
                    <SceneOrLineupSelect
                      flowDiagram={project.flowDiagram}
                      scenes={videoPartNames}
                      value={scenes}
                      onChange={setScenes}
                      style={{ minWidth: 200, width: "100%" }}
                      limit={true}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      }
      footer={null}
      onCancel={() => setIsModalOpen({ modalOpen: false, mode: "create" })}
      className="blings-modal-dynamic-data blings-modal"
    >
      <Layout>
        <Content className="content">
          <div className="data">
            <BlingsCard className="blings-card-dynamic-data">
              {!selectedDataPointId || creatingNewDataPoint ? (
                <p className="blings-card-title">Create your own version</p>
              ) : (
                <>
                  <BlingsDataPointSelector
                    fillDataFromRecord={fillDataFromRecord}
                    selectedDataPointId={selectedDataPointId}
                    setSelectedDataPointId={setSelectedDataPointId}
                    showEditing={true}
                  />
                </>
              )}
              <BlingsDynamicForm
                dynamicFormProps={dynamicFormProps}
                className="dynamic-form-overrides"
                setIsModalOpen={setIsModalOpen}
                dataPointSelectorProps={dataPointSelectorProps}
                target="Default Version"
              />
              <BlingsNewDataInput
                hasDataPoints={hasDataPoints}
                hasFormDataChanged={hasFormDataChanged}
                setIsModalOpen={setIsModalOpen}
                dataInputProps={dataInputProps}
                creatingNewDataPoint={creatingNewDataPoint}
                setCreatingNewDatapoint={setCreatingNewDatapoint}
              />
            </BlingsCard>
          </div>
          {isModalOpen && (
            <div className="demo-sdk">
              <PlayerComponent
                commandBarOptions={{
                  showCopyUrl: true,
                  showExportMp4: true,
                  showFrameIndicator: true,
                  showGlobalMute: true,
                  showSceneSelector: true,
                  showWatchPreview: true,
                }}
                playerOptions={{
                  data: showSDKProps.data,
                  playerVersionToUse: project.playerVersionToUse,
                  projectID: project.id,
                  selectedScenes: debouncedScenes,
                  settings: debouncedSettings,
                  vertical: vertical,
                  callbackIsLoading: setIsLoading,
                  isLoading: isLoading,
                  useScenesFromContext: false,
                }}
              />
            </div>
          )}
        </Content>
      </Layout>
    </BlingsModal>
  );
}
