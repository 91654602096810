import { StaticTemplateProps } from "./StaticTemplate";
import { PATHS } from "../../../PATHS";
import { CREATE_PROJECT_MP5_GEN_BASE_URL } from "../../../consts";

export const ttvStaticContent: StaticTemplateProps = {
  props: {
    title: "Wireframe with AI (Beta)",
    content: [
      {
        subtitle: "1. Write Your Vision:",
        text: "Start by simply describing your concept. Whether it’s a product demo, a customer update, or a personalized message, type it out and let our TTV tool do the heavy lifting.",
      },
      {
        subtitle: "2. Watch It Transform:",
        text: "Our early version of TTV translates your text into a dynamic, visually engaging video. Every element is designed to keep your audience hooked, from start to finish.",
      },
      {
        subtitle: "3. Personalize the Experience:",
        text: "Enhance the video with customer data, making every interaction feel tailored and meaningful. It’s as easy as connecting a few fields to create a unique one-to-one communication.",
      },
    ],
    footerTitle: "Ready to turn your words into action?",
    buttonText: "Write your prompt",
    redirectPage: CREATE_PROJECT_MP5_GEN_BASE_URL,
  },
};

export const aeStaticContent: StaticTemplateProps = {
  props: {
    title: "Replace Your CRM Campaigns with Blings Interactive Videos",
    content: [
      {
        subtitle: "1. Start in Adobe After Effects (AE):",
        text: "Leverage your creative team’s expertise by designing a visually stunning video in AE. This step allows for full control over branding, animations, and storytelling—going far beyond what traditional email marketing can achieve.",
      },
      {
        subtitle: "2. Export Seamlessly with the Blings Extension:",
        text: "Using the Blings AE extension, your team can export the video directly into a Blings project. Each layer is automatically converted into a JSON element, making it fully customizable and ready for data integration. This means your video becomes a dynamic canvas, not just a static asset.",
      },
      {
        subtitle: "3. Personalize and Connect:",
        text: "Blings empowers you to connect customer data to video elements effortlessly. Add personalized touches like names, purchase histories, or tailored calls-to-action that resonate on a one-to-one level. Deliver engagement metrics that outperform traditional email marketing by turning your static campaigns into interactive experiences.",
      },
    ],
    footerTitle: '"Need a video but short on design bandwidth?"',
    footerText:
      "Let us take care of it! Use our motion design services to create the perfect video for your next campaign.",
    buttonText: "Contact Us",
    redirectPage: PATHS.project,
  },
};
