import React, { CSSProperties } from "react";
import "./Steps.scss";
import {
  CurrentStepIcon,
  NextStepCircleIcon,
  CircleCheckedIcon,
} from "../../icons-and-animations/icons";
import classNames from "classnames";
type StepStatus = "process" | "wait" | "finish" | "error" | undefined;

interface Step {
  key: string;
  label: string;
  status?: StepStatus;
}

interface StepsProps {
  steps: Step[];
  currentStep: number;
  stepWrapperStyle?: CSSProperties;
}

const Steps: React.FC<StepsProps> = ({
  steps,
  currentStep,
  stepWrapperStyle,
}) => {
  const getIconByStatus = (status: Step["status"]) => {
    if (status === "process") return <CurrentStepIcon />;
    if (status === "wait") return <NextStepCircleIcon />;
    if (status === "finish") return <CircleCheckedIcon />;
    return <NextStepCircleIcon />;
  };

  return (
    <div className="steps-container" style={stepWrapperStyle}>
      <div className="steps">
        {steps.map((step, index) => {
          const isActive = index <= currentStep;
          const isCurrent = index === currentStep;
          const status = isCurrent ? "process" : isActive ? "finish" : "wait";

          return (
            <div key={index} className="step-item">
              <div className="step-circle">{getIconByStatus(status)}</div>

              {index < steps.length - 1 && (
                <div
                  className={`step-line ${
                    index < currentStep ? "active-line" : "inactive-line"
                  }`}
                />
              )}

              <div
                className={classNames(
                  "step-label",
                  isCurrent || isActive ? "active" : ""
                )}
              >
                {step.label}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Steps;
