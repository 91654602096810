/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const byAccount =
  /* GraphQL */ `query ByAccount($input: ByAccountInput!) {
  byAccount(input: $input) {
    items {
      id
      projectAccountId
      title
      allowCreateDataPoint
      allowDataConnect
      allowCrmConnect
      allowSdkConnect
      allowSendFormData
      analyticsReportUrl
      createdAt
      description
      fileName
      jsonChanges
      jsonVidUrl
      settings
      stateJsonSchemaStr
      settingsJsonSchemaStr
      thumbS3Url
      experimentOptimizationTechnique
      analyticsEnabled
      owner
      aliasId
      playerVersionToUse
      customHtml
      publishedAt
      allowedOrigins
      textToVideoScript
      instantiatedFromUseCase
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ByAccountQueryVariables, APITypes.ByAccountQuery>;
export const getUseCase = /* GraphQL */ `query GetUseCase($id: ID!) {
  getUseCase(id: $id) {
    id
    name
    projectId
    images
    shortDescription
    longDescription
    thumbnail
    demoLink
    videoPreview
    lineupId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUseCaseQueryVariables,
  APITypes.GetUseCaseQuery
>;
export const listUseCases = /* GraphQL */ `query ListUseCases(
  $filter: ModelUseCaseFilterInput
  $limit: Int
  $nextToken: String
) {
  listUseCases(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      projectId
      images
      shortDescription
      longDescription
      thumbnail
      demoLink
      videoPreview
      lineupId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUseCasesQueryVariables,
  APITypes.ListUseCasesQuery
>;
export const getUseCaseTag = /* GraphQL */ `query GetUseCaseTag($tag: String!) {
  getUseCaseTag(tag: $tag) {
    tag
    useCaseIds
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUseCaseTagQueryVariables,
  APITypes.GetUseCaseTagQuery
>;
export const listUseCaseTags = /* GraphQL */ `query ListUseCaseTags(
  $tag: String
  $filter: ModelUseCaseTagFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUseCaseTags(
    tag: $tag
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      tag
      useCaseIds
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUseCaseTagsQueryVariables,
  APITypes.ListUseCaseTagsQuery
>;
export const getAccount = /* GraphQL */ `query GetAccount($id: ID!) {
  getAccount(id: $id) {
    id
    gid
    name
    projects {
      nextToken
      __typename
    }
    fileuploads {
      originalName
      createdAt
      fileName
      fileStatus
      fileError
      numRecords
      numErrors
      totalRecords
      writtenRecords
      __typename
    }
    integrations {
      hubspot
      __typename
    }
    aliasId
    fonts {
      fontFamily
      __typename
    }
    accountType {
      levelName
      __typename
    }
    minisiteDomain
    createNewVideosWithBlingLogo
    mfaEnabled
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAccountQueryVariables,
  APITypes.GetAccountQuery
>;
export const listAccounts = /* GraphQL */ `query ListAccounts(
  $filter: ModelAccountFilterInput
  $limit: Int
  $nextToken: String
) {
  listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      gid
      name
      aliasId
      minisiteDomain
      createNewVideosWithBlingLogo
      mfaEnabled
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAccountsQueryVariables,
  APITypes.ListAccountsQuery
>;
export const getFormData = /* GraphQL */ `query GetFormData($id: ID!) {
  getFormData(id: $id) {
    id
    accountOwner
    projectFormDataId
    project {
      id
      projectAccountId
      title
      allowCreateDataPoint
      allowDataConnect
      allowCrmConnect
      allowSdkConnect
      allowSendFormData
      analyticsReportUrl
      createdAt
      description
      fileName
      jsonChanges
      jsonVidUrl
      settings
      stateJsonSchemaStr
      settingsJsonSchemaStr
      thumbS3Url
      experimentOptimizationTechnique
      analyticsEnabled
      owner
      aliasId
      playerVersionToUse
      customHtml
      publishedAt
      allowedOrigins
      textToVideoScript
      instantiatedFromUseCase
      updatedAt
      __typename
    }
    data
    sessionId
    userId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFormDataQueryVariables,
  APITypes.GetFormDataQuery
>;
export const listFormData = /* GraphQL */ `query ListFormData(
  $filter: ModelFormDataFilterInput
  $limit: Int
  $nextToken: String
) {
  listFormData(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      accountOwner
      projectFormDataId
      data
      sessionId
      userId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFormDataQueryVariables,
  APITypes.ListFormDataQuery
>;
export const formDataByDate = /* GraphQL */ `query FormDataByDate(
  $projectFormDataId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFormDataFilterInput
  $limit: Int
  $nextToken: String
) {
  formDataByDate(
    projectFormDataId: $projectFormDataId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      accountOwner
      projectFormDataId
      data
      sessionId
      userId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FormDataByDateQueryVariables,
  APITypes.FormDataByDateQuery
>;
export const getNotification = /* GraphQL */ `query GetNotification($id: ID!) {
  getNotification(id: $id) {
    id
    type
    subType
    status
    title
    message
    read
    owner
    projectId
    project {
      id
      projectAccountId
      title
      allowCreateDataPoint
      allowDataConnect
      allowCrmConnect
      allowSdkConnect
      allowSendFormData
      analyticsReportUrl
      createdAt
      description
      fileName
      jsonChanges
      jsonVidUrl
      settings
      stateJsonSchemaStr
      settingsJsonSchemaStr
      thumbS3Url
      experimentOptimizationTechnique
      analyticsEnabled
      owner
      aliasId
      playerVersionToUse
      customHtml
      publishedAt
      allowedOrigins
      textToVideoScript
      instantiatedFromUseCase
      updatedAt
      __typename
    }
    createdAt
    extra
    updatedAt
    projectNotificationsId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetNotificationQueryVariables,
  APITypes.GetNotificationQuery
>;
export const listNotifications = /* GraphQL */ `query ListNotifications(
  $filter: ModelNotificationFilterInput
  $limit: Int
  $nextToken: String
) {
  listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      subType
      status
      title
      message
      read
      owner
      projectId
      createdAt
      extra
      updatedAt
      projectNotificationsId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNotificationsQueryVariables,
  APITypes.ListNotificationsQuery
>;
export const getProject = /* GraphQL */ `query GetProject($id: ID!) {
  getProject(id: $id) {
    id
    projectAccountId
    title
    account {
      id
      gid
      name
      aliasId
      minisiteDomain
      createNewVideosWithBlingLogo
      mfaEnabled
      createdAt
      updatedAt
      __typename
    }
    videoParts {
      name
      jsonUrl
      mods
      updatedAt
      hasVideo
      origin
      playerVersionToUse
      __typename
    }
    allowCreateDataPoint
    allowDataConnect
    allowCrmConnect
    allowSdkConnect
    allowSendFormData
    analyticsReportUrl
    createdAt
    dataFiles {
      fileName
      tag
      createdAt
      __typename
    }
    description
    fileName
    jsonChanges
    jsonVidUrl
    playerSettings {
      autoplay
      autoplay_delay
      posterFrame
      showTimeline
      storiesMode
      color_loader
      color_ctrlBtns
      color_rail
      color_progress
      color_thumb
      color_bg
      muted
      autoReplay
      showBlingsLogo
      blingsLogoColor
      loadingText
      loadingImage
      __typename
    }
    projectVersions {
      nextToken
      __typename
    }
    formData {
      nextToken
      __typename
    }
    settings
    stateJsonSchemaStr
    settingsJsonSchemaStr
    thumbS3Url
    experiments {
      id
      type
      __typename
    }
    experimentOptimizationTechnique
    analyticsEnabled
    owner
    renders {
      nextToken
      __typename
    }
    notifications {
      nextToken
      __typename
    }
    aliasId
    minisiteConfigs {
      scenes
      title
      description
      thumbUrl
      faviconUrl
      preCreate
      __typename
    }
    playerVersionToUse
    customHtml
    dynamicThumbnails {
      name
      id
      createdAt
      updatedAt
      __typename
    }
    publishedAt
    allowedOrigins
    flowDiagram {
      __typename
    }
    textToVideoScript
    instantiatedFromUseCase
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProjectQueryVariables,
  APITypes.GetProjectQuery
>;
export const listProjects = /* GraphQL */ `query ListProjects(
  $filter: ModelProjectFilterInput
  $limit: Int
  $nextToken: String
) {
  listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      projectAccountId
      title
      allowCreateDataPoint
      allowDataConnect
      allowCrmConnect
      allowSdkConnect
      allowSendFormData
      analyticsReportUrl
      createdAt
      description
      fileName
      jsonChanges
      jsonVidUrl
      settings
      stateJsonSchemaStr
      settingsJsonSchemaStr
      thumbS3Url
      experimentOptimizationTechnique
      analyticsEnabled
      owner
      aliasId
      playerVersionToUse
      customHtml
      publishedAt
      allowedOrigins
      textToVideoScript
      instantiatedFromUseCase
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProjectsQueryVariables,
  APITypes.ListProjectsQuery
>;
export const getProjectByAliasId = /* GraphQL */ `query GetProjectByAliasId(
  $aliasId: String!
  $projectAccountId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProjectFilterInput
  $limit: Int
  $nextToken: String
) {
  getProjectByAliasId(
    aliasId: $aliasId
    projectAccountId: $projectAccountId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      projectAccountId
      title
      allowCreateDataPoint
      allowDataConnect
      allowCrmConnect
      allowSdkConnect
      allowSendFormData
      analyticsReportUrl
      createdAt
      description
      fileName
      jsonChanges
      jsonVidUrl
      settings
      stateJsonSchemaStr
      settingsJsonSchemaStr
      thumbS3Url
      experimentOptimizationTechnique
      analyticsEnabled
      owner
      aliasId
      playerVersionToUse
      customHtml
      publishedAt
      allowedOrigins
      textToVideoScript
      instantiatedFromUseCase
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProjectByAliasIdQueryVariables,
  APITypes.GetProjectByAliasIdQuery
>;
export const getProjectChangeActivity =
  /* GraphQL */ `query GetProjectChangeActivity($id: ID!) {
  getProjectChangeActivity(id: $id) {
    projectId
    before {
      projectAccountId
      title
      allowCreateDataPoint
      allowDataConnect
      allowCrmConnect
      allowSdkConnect
      analyticsReportUrl
      createdAt
      description
      fileName
      jsonChanges
      jsonVidUrl
      settings
      stateJsonSchemaStr
      settingsJsonSchemaStr
      thumbS3Url
      experimentOptimizationTechnique
      type
      owner
      __typename
    }
    after {
      projectAccountId
      title
      allowCreateDataPoint
      allowDataConnect
      allowCrmConnect
      allowSdkConnect
      analyticsReportUrl
      createdAt
      description
      fileName
      jsonChanges
      jsonVidUrl
      settings
      stateJsonSchemaStr
      settingsJsonSchemaStr
      thumbS3Url
      experimentOptimizationTechnique
      type
      owner
      __typename
    }
    owner
    email
    origin
    accountOwner
    createdAt
    id
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetProjectChangeActivityQueryVariables,
    APITypes.GetProjectChangeActivityQuery
  >;
export const listProjectChangeActivities =
  /* GraphQL */ `query ListProjectChangeActivities(
  $filter: ModelProjectChangeActivityFilterInput
  $limit: Int
  $nextToken: String
) {
  listProjectChangeActivities(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      projectId
      owner
      email
      origin
      accountOwner
      createdAt
      id
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListProjectChangeActivitiesQueryVariables,
    APITypes.ListProjectChangeActivitiesQuery
  >;
export const getProjectVersionChangeActivity =
  /* GraphQL */ `query GetProjectVersionChangeActivity($id: ID!) {
  getProjectVersionChangeActivity(id: $id) {
    projectId
    before {
      projectAccountId
      title
      allowCreateDataPoint
      allowDataConnect
      allowCrmConnect
      allowSdkConnect
      analyticsReportUrl
      createdAt
      description
      fileName
      jsonChanges
      jsonVidUrl
      settings
      stateJsonSchemaStr
      settingsJsonSchemaStr
      thumbS3Url
      experimentOptimizationTechnique
      type
      owner
      __typename
    }
    after {
      projectAccountId
      title
      allowCreateDataPoint
      allowDataConnect
      allowCrmConnect
      allowSdkConnect
      analyticsReportUrl
      createdAt
      description
      fileName
      jsonChanges
      jsonVidUrl
      settings
      stateJsonSchemaStr
      settingsJsonSchemaStr
      thumbS3Url
      experimentOptimizationTechnique
      type
      owner
      __typename
    }
    owner
    origin
    accountOwner
    createdAt
    id
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetProjectVersionChangeActivityQueryVariables,
    APITypes.GetProjectVersionChangeActivityQuery
  >;
export const listProjectVersionChangeActivities =
  /* GraphQL */ `query ListProjectVersionChangeActivities(
  $filter: ModelProjectVersionChangeActivityFilterInput
  $limit: Int
  $nextToken: String
) {
  listProjectVersionChangeActivities(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      projectId
      owner
      origin
      accountOwner
      createdAt
      id
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListProjectVersionChangeActivitiesQueryVariables,
    APITypes.ListProjectVersionChangeActivitiesQuery
  >;
export const getProjectVersion =
  /* GraphQL */ `query GetProjectVersion($id: ID!) {
  getProjectVersion(id: $id) {
    id
    accountOwner
    owner
    stateJsonSchemaStr
    settingsJsonSchemaStr
    settings
    videoParts {
      name
      jsonUrl
      mods
      updatedAt
      hasVideo
      origin
      playerVersionToUse
      __typename
    }
    updatedAt
    playerVersionToUse
    experiments {
      id
      type
      __typename
    }
    experimentOptimizationTechnique
    textToVideoScript
    flowDiagram {
      __typename
    }
    createdAt
    projectProjectVersionsId
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetProjectVersionQueryVariables,
    APITypes.GetProjectVersionQuery
  >;
export const listProjectVersions = /* GraphQL */ `query ListProjectVersions(
  $filter: ModelProjectVersionFilterInput
  $limit: Int
  $nextToken: String
) {
  listProjectVersions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      accountOwner
      owner
      stateJsonSchemaStr
      settingsJsonSchemaStr
      settings
      updatedAt
      playerVersionToUse
      experimentOptimizationTechnique
      textToVideoScript
      createdAt
      projectProjectVersionsId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProjectVersionsQueryVariables,
  APITypes.ListProjectVersionsQuery
>;
export const getServerRender = /* GraphQL */ `query GetServerRender($id: ID!) {
  getServerRender(id: $id) {
    id
    projectId
    project {
      id
      projectAccountId
      title
      allowCreateDataPoint
      allowDataConnect
      allowCrmConnect
      allowSdkConnect
      allowSendFormData
      analyticsReportUrl
      createdAt
      description
      fileName
      jsonChanges
      jsonVidUrl
      settings
      stateJsonSchemaStr
      settingsJsonSchemaStr
      thumbS3Url
      experimentOptimizationTechnique
      analyticsEnabled
      owner
      aliasId
      playerVersionToUse
      customHtml
      publishedAt
      allowedOrigins
      textToVideoScript
      instantiatedFromUseCase
      updatedAt
      __typename
    }
    scenes
    data
    env
    outputFileName
    quality
    frameToStart
    frameToEnd
    format
    status {
      ready
      status
      downloadLink
      code
      __typename
    }
    cognitoUserIdentifier
    cognitoGroupOwner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetServerRenderQueryVariables,
  APITypes.GetServerRenderQuery
>;
export const listServerRenders = /* GraphQL */ `query ListServerRenders(
  $filter: ModelServerRenderFilterInput
  $limit: Int
  $nextToken: String
) {
  listServerRenders(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      projectId
      scenes
      data
      env
      outputFileName
      quality
      frameToStart
      frameToEnd
      format
      cognitoUserIdentifier
      cognitoGroupOwner
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListServerRendersQueryVariables,
  APITypes.ListServerRendersQuery
>;
