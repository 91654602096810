import LogRocket from "logrocket";
import React, { createContext, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { getCurrentUser, signIn, signOut } from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";
import { signInWithRedirect } from "aws-amplify/auth";
import { get, post } from "aws-amplify/api";
import { ConfigProvider } from "antd";
import { Authenticator, ThemeProvider } from "@aws-amplify/ui-react";
import { ConfigureApp, withDefaultSignIn } from "@blings/shared-auth-component";
import { Amplify } from "aws-amplify";
import { getENV, info_data } from "../config";
import BlingsPlatformTheme from "../config/antdTheme";
import { PATHS, PROJECT_PATHS, toPath } from "../PATHS";
import { Provider, rootStore } from "../stores/Root";
import awsmobile from "../aws-exports";
import { OAuthCallback } from "./Integrations/OAuthCallback";
import withSetupTOTPMFA from "./Auth/MFA/withSetupTOTPMFA";
import NavBar from "./NavBar/NavBar";
import DataUploads from "./DataUploads/DataUploads";
import WelcomeProjectsGallery from "./WelcomeProjectsGallery/WelcomeProjectsGallery";
import { ProjectCreatePage } from "./ProjectCreateAndUpdate/ProjectCreatePage";
import AccountSettings from "./AccountSettings/AccountSettings";
import ProjectView from "./ProjectView/ProjectView";
import "./App.scss";
import UseCaseGallery from "./WelcomeProjectsGallery/UseCaseGallery/UseCaseGallery";
import { PlayerProvider } from "../contexts/playerContext";
import { NEW_REST_API, NEW_REST_API__V2__PUBSUB } from "../consts";

const awsmobile_changed: typeof awsmobile = JSON.parse(
  JSON.stringify(awsmobile)
);

const blingsApi = awsmobile_changed.aws_cloud_logic_custom.find(
  (api) => api.name === "BlingsAPI"
) as (typeof awsmobile)["aws_cloud_logic_custom"][number] & {
  custom_headers?: () => Promise<Record<string, string>>;
};
blingsApi.endpoint = `https://${getENV() === "dev" ? "dev." : ""}api.blings.io`;

blingsApi.custom_headers = async () => {
  return {
    "Access-Control-Allow-Credentials": "true",
    "Content-Type": "application/json",
  };
};
awsmobile_changed.aws_appsync_graphqlEndpoint = `https://${
  getENV() === "dev" ? "dev." : ""
}graphql.blings.io/graphql`;
// ConfigureApp(awsmobile, Amplify, signInWithRedirect);

//ConfigureApp(awsmobile_changed, Amplify, signInWithRedirect, getENV() === "dev" ? "http://localhost:3102" : "");
const isLocal = window.location.hostname.includes("localhost");
ConfigureApp(
  awsmobile_changed,
  Amplify,
  signInWithRedirect,
  isLocal
    ? "http://localhost:3102"
    : getENV() === "dev"
    ? "https://dev.platform.blings.io"
    : "https://platform.blings.io"
);

// console.log({ config3 });
async function addPubSubPermission() {
  const apiName = NEW_REST_API;
  const path = NEW_REST_API__V2__PUBSUB;
  const myInit = {
    crossDomain: true,
    body: {},
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };
  try {
    await post({
      apiName,
      path,
      options: myInit,
    }).response;
  } catch (e) {
    console.error(e);
  }
}

const App: React.FC = () => {
  const [enforcedMFA, setEnforcedMFA] = React.useState(0);
  useEffect(() => {
    window.addEventListener(
      "keypress",
      (function () {
        const strToType = "thingsandstuff";
        let strTyped = "";
        return function (event) {
          const character = String.fromCharCode(event.which);
          strTyped += character;
          if (strToType.indexOf(strTyped) === -1) strTyped = "";
          else if (strTyped === strToType) {
            const themeStyle = document.createElement("style");
            (window as any).themeS = themeStyle;
            themeStyle.innerHTML = `*::-webkit-scrollbar-thumb {
              // background-color: black !important;
              background: linear-gradient(178deg, #9a6646, #c6b548) !important; 
              border-radius: 16px !important;
              border: 4px solid #fff !important;
          } :root {--blings_pink: orange; --blings_background3:linear-gradient(178deg, #9062c4, #32e210);}
           .ant-layout-header {
            background: linear-gradient(139deg, #7d17d7, #d5986b);
          }`;
            document.body.append(themeStyle);
            rootStore.uiStore.setIsPsychedelic(true);
            strTyped = "";
          }
        };
      })()
    );
    (async () => {
      // Stuff that we want to wait for before rendering the app
      rootStore.projectsStore.loadProjectsFromLocalStorage();
      rootStore.userStore.load().then(() => {
        rootStore.projectsStore.loadProjects();
        rootStore.notificationStore.init();
      });

      if (process.env.NODE_ENV === "production") {
        LogRocket.init("xodu2t/blings_platform-q7eko");
        (async () => {
          const userStore = rootStore.userStore;
          LogRocket.identify("USER_IN_APP_ID", {
            name: userStore?.sub as string,
            email: userStore?.email as string,
            id: userStore?.sub as string,
          });
        })();
      }
      addPubSubPermission();
    })();
  }, []);

  // post({
  //   apiName: "BlingsAPI",
  //   path: "/v2/private/auth/cookies",
  //   options: {
  //     body: {
  //       cookieHere: 123
  //     },
  //     withCredentials: true
  //   }
  // })

  return (
    <ConfigProvider theme={BlingsPlatformTheme}>
      <PlayerProvider>
        <Provider value={rootStore}>
          <Router>
            <Routes>
              <Route path="/" element={<NavBar />}>
                <Route index element={<WelcomeProjectsGallery />} />

                <Route path="use-cases" element={<UseCaseGallery />} />
                <Route
                  path={toPath(PATHS.settings)}
                  element={<AccountSettings />}
                />
                <Route path={toPath(PATHS.data)} element={<DataUploads />} />
                <Route
                  path={toPath(PATHS.create_new_project)}
                  element={<ProjectCreatePage />}
                />
                <Route
                  path={toPath(PATHS.project, ":id", "*")}
                  element={<ProjectView />}
                />

                <Route
                  path={toPath(PATHS.project, ":id")}
                  element={<Navigate to={PROJECT_PATHS.overview} replace />}
                />

                <Route
                  path={toPath(PATHS.oauthCallback, ":platform")}
                  element={<OAuthCallback />}
                />
              </Route>
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </Router>
          <ToastContainer
            position="bottom-left"
            autoClose={2500}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </Provider>
      </PlayerProvider>
    </ConfigProvider>
  );
};

export default withDefaultSignIn(withSetupTOTPMFA(App), {
  config: {
    amplify: {
      Hub: Hub,
      getCurrentUser: getCurrentUser,
      signIn: signIn,
      signOut: signOut,
      get: get,
      post: post,
    },
    ui: {
      Authenticator: Authenticator,
      ThemeProvider: ThemeProvider,
    },
    environment: getENV(),
  },
  infoData: {
    contact_email: info_data.contactMail,
    support: info_data.support,
  },
});
