import { useEffect, useState } from "react";
import "./UseCaseTemplate.scss";
import { UseCase, UseCaseTag } from "../../../API";
import { Button } from "antd";
import SdkPlayer from "../../../components/SdkPlayer";
import { getENV } from "../../../config";
import Stepes from "../../../components/Steps/Steps";
import Carousel from "../../../components/Carousel/Carousel";
import UseCasePreview from "./UseCasePreview";
import { IJSONObject } from "@blings/blings-player";
import { get } from "aws-amplify/api";
import { NEW_REST_API, REST_API_V2__GET_USE_CASES } from "../../../consts";

interface IProjectPublic {
  id: string | undefined;
  title: string;
  settings: string;
}
const steps = [
  {
    title: "Preview",
    content: "First-content",
  },
  {
    title: "Customize",
    content: "Second-content",
  },
  {
    title: "Done",
    content: "Last-content",
  },
];

export default function UseCaseTemplate({
  usecase,
  tags = [],
  accountId,
}: {
  usecase: UseCase | null;
  tags: Array<UseCaseTag> | [];
  accountId: string;
}) {
  const [current, setCurrent] = useState(0);
  const [isSavingTemplate, setIsSavingTemplate] = useState(false);
  const [liveControl, setLiveControl] = useState<IJSONObject>({});
  useEffect(() => {
    async function fetchProject() {
      if (!usecase) return;
      try {
        const response = await get({
          apiName: NEW_REST_API,
          path: `${REST_API_V2__GET_USE_CASES}/${usecase.id}/project`,
        }).response;

        const project =
          (await response.body.json()) as unknown as IProjectPublic;
        setLiveControl(JSON.parse(project.settings));
      } catch (error) {
        console.error("Error fetching project or video parts:", error);
      }
    }

    fetchProject();
  }, []);
  if (!usecase) return null;
  const images = usecase.images || [];

  const next = () => {
    setCurrent(current + 1);
  };

  const getTextBtn = () => {
    if (current === 0) return "Next";
    if (current === 1) return "Save and Continue";
    return "Go to platform";
  };

  const items = steps.map((item) => ({
    key: item.title,
    label: item.title,
  }));

  const handleNextBtn = async () => {
    if (current === 0) next();
    if (current === 1) {
      setIsSavingTemplate(true);
      try {
        // await  handleInstantiateUseCase();
      } catch (e) {}
      setIsSavingTemplate(false);
    }
  };

  return (
    <div className="use-case-main-wrapper">
      <div className="use-case-left-side">
        {current === 0 ? (
          <Carousel images={images} showPagination={true} />
        ) : (
          <div className="customize-video-wrapper">
            <SdkPlayer
              sdkParams={{
                project: {
                  id: usecase.projectId,
                  env: getENV(),
                  liveControlData: liveControl,
                },
                data: {},
                scenes: [{ lineup: usecase.lineupId || "" }],
                settings: {},
              }}
            />
          </div>
        )}
      </div>
      <div className="use-case-right-side">
        <div className="steps-wrapper">
          <div className="use-case-content">
            <Stepes steps={items} currentStep={current} />
            {current === 0 ? (
              <div className="use-case-title">
                <h1>{usecase.name} Use-Case</h1>
                <div className="tag-list">
                  {tags.map((tag) => (
                    <div key={tag.tag} className="usecase-tag">
                      {tag.tag}
                    </div>
                  ))}
                </div>
                <p>{usecase.shortDescription} </p>
              </div>
            ) : (
              <UseCasePreview
                handleLiveControlChange={setLiveControl}
                liveControl={liveControl as IJSONObject}
              />
            )}
          </div>
        </div>

        {current < steps.length && (
          <div
            className="next-btn-wrapper"
            style={{ marginBottom: current === 0 ? "35px" : 0 }}
          >
            <Button
              className="next-step-btn"
              onClick={() => handleNextBtn()}
              disabled={isSavingTemplate}
            >
              {getTextBtn()}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
