import { useEffect, useRef, useState } from "react";
import { Tooltip } from "antd";
import "./UseCasePreview.scss";
import { UseCase } from "../../../API";
import { UploadIcon, HelpCircleIcon } from "../../../assets/Icons";

import { IJSONObject } from "@blings/blings-player";
import { getTypesFromLiveControl } from "../../../utils/getTypesFromLiveControl";

export default function UseCaseTemplate({
  handleLiveControlChange,
  liveControl,
}: {
  handleLiveControlChange: React.Dispatch<React.SetStateAction<IJSONObject>>;
  liveControl: IJSONObject;
}) {
  const [liveControlKeysSeparatedByType, setLiveControlKeysSeparatedByType] =
    useState<{
      images: string[];
      colors: string[];
    } | null>(null);

  const fileInputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    const liveControlKeysSeparatedByType = getTypesFromLiveControl(liveControl);
    setLiveControlKeysSeparatedByType(liveControlKeysSeparatedByType);
  }, []);

  const tooltipText = (
    <span>
      Accepted formats: <br /> PNG, JPG, WEBP{" "}
    </span>
  );

  return (
    <div className="customize-use-case-content">
      <div className="customize-use-case-title">
        <h1>Customize Your Template</h1>
      </div>

      <div className="about-template">
        <h4>
          Tailor the settings below to align this template with your brand.
        </h4>

        {liveControlKeysSeparatedByType && (
          <div className="brand-customization-wrapper">
            {liveControlKeysSeparatedByType.images.map((key, index) => (
              <div key={`image-${index}`}>
                <div
                  className="container-file-upload"
                  onClick={() => {
                    fileInputRefs.current[index]?.click();
                  }}
                >
                  <label className="custom-file-upload">
                    <img src={liveControl[key] as string} />
                  </label>
                  <input
                    onChange={(e) => {
                      handleLiveControlChange((prev) => ({
                        ...prev,
                        [key]: e.target.value,
                      }));
                    }}
                    ref={(el) => (fileInputRefs.current[index] = el)}
                    type="file"
                    accept="image/png, image/jpeg, image/webp"
                  />
                  <div className="hover-file-upload">
                    <UploadIcon />
                    <span>Replace {key}</span>
                  </div>
                </div>
              </div>
            ))}

            <div className="help-icon">
              <Tooltip placement="left" title={tooltipText}>
                <div>
                  <HelpCircleIcon />
                </div>
              </Tooltip>
            </div>

            {liveControlKeysSeparatedByType.colors.length > 0 && (
              <div className="color-item-container">
                {liveControlKeysSeparatedByType.colors.map((key, index) => (
                  <div className="color-item-content">
                    <span>{key}</span>
                    <div key={`color-${index}`} className="color-item">
                      <input
                        style={{
                          backgroundColor: liveControl[key] as string,
                        }}
                        type="color"
                        value={liveControl[key] as string}
                        onChange={(e) => {
                          const newColor = e.target.value;
                          handleLiveControlChange((prev) => ({
                            ...prev,
                            [key]: newColor,
                          }));
                        }}
                      />
                      <label>{liveControl[key]}</label>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
