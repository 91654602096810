import React, { useState } from "react";
import "./Carousel.scss";
import { LeftArrow, RightArrow } from "../../icons-and-animations/icons";

interface CarouselProps {
  images: string[];
  showPagination: boolean;
}

const Carousel: React.FC<CarouselProps> = ({ images, showPagination }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalImages = images.length;

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalImages);
  };

  const goToPrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + totalImages) % totalImages);
  };

  return (
    <div className="carousel">
      <div className="carousel-image">
        <img src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} />
      </div>
      {showPagination && totalImages > 1 ? (
        <div className="carousel-pagination">
          <button
            className="arrow"
            onClick={goToPrev}
            aria-label="Previous Slide"
          >
            <LeftArrow />
          </button>
          <div className="dots">
            {images.map((_, index) => (
              <span
                key={index}
                className={`dot ${index === currentIndex ? "active" : ""}`}
              ></span>
            ))}
          </div>
          <button className="arrow" onClick={goToNext} aria-label="Next Slide">
            <RightArrow />
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default Carousel;
