import { useEffect, useState } from "react";
import BlingsSearch from "../../view/ProjectsList/BlingsSearch";
import { useNavigate } from "react-router-dom";
import { PATHS, toPath } from "../../PATHS";
import ProjectGalleryCard from "../../view/WelcomeProjectsGallery/ProjectGallery/ProjectGalleryCard";
import UseCaseGalleryCard from "../../view/WelcomeProjectsGallery/UseCaseGallery/UseCaseGalleryCard";
import "./CardsGallery.scss";
import { IProjectModel } from "../../stores/project/projectModel";
import { UseCase } from "../../API";

interface ProjectGalleryProps {
  cardType: "project";
  items: IProjectModel[];
  title?: string;
  searchPlaceholder?: string;
  handleClick: (id: string) => void;
  getItemName: "title";
  userHasMultipleAccounts?: boolean;
}

interface UseCaseGalleryProps {
  cardType: "usecase";
  items: UseCase[];
  title?: string;
  searchPlaceholder?: string;
  handleClick: (item: UseCase) => void;
  getItemName: "name";
  userHasMultipleAccounts?: boolean;
}

type CardsGalleryProps = ProjectGalleryProps | UseCaseGalleryProps;

export default function CardsGallery(props: CardsGalleryProps) {
  const {
    title = "Your Projects",
    searchPlaceholder = "Search projects",
    cardType,
    handleClick,
    getItemName,
    items,
    userHasMultipleAccounts = false,
  } = props;

  const history = useNavigate();
  const [searchInput, setSearchInput] = useState<string>("");
  const [filteredItems, setFilteredItems] = useState<typeof items>([]);

  useEffect(() => {
    if (!searchInput) {
      setFilteredItems(items);
    } else {
      const filtered =
        cardType === "project"
          ? items.filter((item) =>
              item.title.toLowerCase().includes(searchInput.toLowerCase())
            )
          : items.filter((item) =>
              item.name.toLowerCase().includes(searchInput.toLowerCase())
            );
      setFilteredItems(filtered);
    }
  }, [items, searchInput]);

  const options =
    cardType === "project"
      ? items.map((item) => ({
          label:
            item.title +
            (userHasMultipleAccounts ? ` | ${item.projectAccountId}` : ""),
          key: item.id,
          ...item,
        }))
      : items.map((item) => ({
          label: item[getItemName],
          key: item.id,
        }));

  return (
    <div className="project-gallery">
      <div className={"project-gallery-header " + cardType}>
        <div className="Left-Side">
          <div className={"SubHeader " + cardType}>{title}</div>
        </div>
        <div className="Right-Side">
          <BlingsSearch
            currentInput={searchInput}
            options={options}
            setCurrentInput={setSearchInput}
            setResults={setFilteredItems}
            placeholder={searchPlaceholder}
            handleSelect={(e) => {
              history(toPath(PATHS.project, e));
            }}
          />
        </div>
      </div>

      <div className="cards-gallery-wrapper">
        {filteredItems.length === 0 ? (
          <div className="no-items-found">No items found</div>
        ) : (
          filteredItems.map((item, index) =>
            cardType === "project" ? (
              <ProjectGalleryCard
                project={item as IProjectModel}
                key={item.id}
                handleClick={handleClick}
                userHasMultipleAccounts={userHasMultipleAccounts}
              />
            ) : (
              <UseCaseGalleryCard
                usecase={item as UseCase}
                key={item.id}
                handleClick={handleClick}
              />
            )
          )
        )}
      </div>
    </div>
  );
}
