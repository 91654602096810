import React from "react";
import lottieLoader from "../assets/new-spinner.json";
import Lottie from "react-lottie-player";

export default function LottieLoader() {
  return (
    <div className="spinner">
      <Lottie
        goTo={lottieLoader.op}
        play={true}
        speed={1}
        loop={true}
        animationData={lottieLoader}
        direction={1}
        style={{
          height: 150,
          width: 150,
        }}
      />
      <span>Loading Projects</span>
    </div>
  );
}
