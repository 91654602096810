import { get, post } from "aws-amplify/api";
import { types, flow } from "mobx-state-tree";
import {
  NEW_REST_API,
  REST_API_V2__GET_USE_CASES,
  REST_API_V2__INSTANTIATE_USE_CASE,
} from "../../consts";
import { UseCase, UseCaseTag } from "../../API";

export const UseCasesModel = types
  .model("UseCasesModel", {
    tags: types.optional(types.array(types.frozen<UseCaseTag>()), []),
    useCases: types.optional(types.array(types.frozen<UseCase>()), []),
  })
  .actions((self) => ({
    getUseCases: flow(function* () {
      try {
        const response = yield get({
          apiName: NEW_REST_API,
          path: REST_API_V2__GET_USE_CASES,
        }).response;
        const data = yield response.body.json();
        self.useCases.replace(data.useCases);
        self.tags.replace(data.tags);
      } catch (e) {
        console.error(e);
      }
    }),
  }))
  .actions((self) => ({
    afterCreate: () => {
      self.getUseCases();
    },

    getUseCasesFromTags: (tags: Array<string>) => {
      if (tags.length === 0) return self.useCases;
      const useCaseTags = self.tags.filter((tag) => tags.includes(tag.tag));
      const useCaseIds = Array.from(
        new Set(useCaseTags.flatMap((tag) => tag.useCaseIds))
      );
      return self.useCases.filter((useCases) =>
        useCaseIds.includes(useCases.id)
      );
    },
    instantiateUseCase: async (accountId: string, useCaseId: string) => {
      await post({
        apiName: NEW_REST_API,
        path: REST_API_V2__INSTANTIATE_USE_CASE(accountId, useCaseId),
      });
    },
  }))
  .views((self) => ({
    useCasesList: () => {
      return self.useCases;
    },
    useCasesTagsList: () => {
      return self.tags;
    },
  }));
