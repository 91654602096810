import { Button, Modal } from "antd";
import { useCallback, useEffect, useState } from "react";
import { LeftOutlined } from "@ant-design/icons";
import CardsGallery from "../../../components/CardsGallery/CardsGallery";
import { useMst } from "../../../stores/Root";
import "./UseCaseGallery.scss";
import { useNavigate } from "react-router-dom";
import { toPath } from "../../../PATHS";
import UseCaseTemplate from "./UseCaseTemplate";
import { UseCase, UseCaseTag } from "../../../API";
import { observer } from "mobx-react-lite";

const UseCaseGallery = observer(() => {
  const history = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedUseCase, setSelectedUseCase] = useState<{
    useCase: UseCase;
    tags: Array<UseCaseTag>;
  } | null>(null);

  const {
    tags,
    useCases,
    accountId,
  }: {
    tags?: Array<UseCaseTag>;
    useCases?: Array<UseCase>;
    accountId?: string;
  } = useMst((store) => {
    return {
      tags: store.useCasesStore.useCasesTagsList(),
      useCases: store.useCasesStore.useCasesList(),
      accountId: store.userStore.mainAccount?.id,
    };
  });

  useEffect(() => {}, [useCases?.length]);

  const handleSelectUseCase = useCallback(
    (useCase: UseCase) => {
      const filteredTags = tags?.filter((tag: UseCaseTag) =>
        tag.useCaseIds.includes(useCase.id)
      );
      setSelectedUseCase({ useCase, tags: filteredTags || [] });
      setIsModalVisible(true);
    },
    [tags]
  );

  useEffect(() => {
    if (!isModalVisible) {
      setSelectedUseCase(null);
    }
  }, [isModalVisible]);

  return (
    <div className="use-case-gallery">
      <div className="use-case-gallery-header">
        <Button
          type="text"
          className="back-btn"
          onClick={() => history(toPath("welcome"))}
        >
          <LeftOutlined size={12} />
          Back
        </Button>
      </div>
      <div className="use-case-gallery-cards">
        <CardsGallery
          cardType="usecase"
          items={useCases || []}
          title="Start With a Use Cases"
          searchPlaceholder="Search Use Cases"
          handleClick={handleSelectUseCase}
          getItemName="name"
        />
      </div>
      {selectedUseCase ? (
        <Modal
          width={900}
          style={{ borderRadius: 20, overflow: "auto" }}
          className="use-case-modal"
          centered
          open={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
          closable={true}
          destroyOnClose={true}
          maskClosable={true}
        >
          <UseCaseTemplate
            usecase={selectedUseCase?.useCase || null}
            tags={selectedUseCase?.tags}
            accountId={accountId || ""}
          />
        </Modal>
      ) : null}
    </div>
  );
});

export default UseCaseGallery;
